import React from 'react'
import { articleImage } from 'images/hulp'
import { Link } from 'gatsby'
import Layout from '../../layouts'
import * as images from 'images/home'
import CtaRow from 'components/cta-row'
import Helmet from 'react-helmet'
import ClientCard from 'components/client-card'
import { smeerm_1, smeerm_2, smeerm_3 } from 'images'
import { window } from 'browser-monads'

import config from '../../config'
const { appUrl, signupRoute } = config

const dummyKlantVerhaal = {
  pageTitle: '“Ik kan onze salarisadministratie nu doen binnen 10 minuten”',
  pageStreamer: 'Avy te Amsterdam, Interview met Ilse van Nierop',
  useCase: 'restaurant',
  person: {
    name: 'Saskia Zwijsen  en Martine van Leeuwen',
    functionTitle: "Leidinggevenden Smeer'm zaak",
    companyLogo: 'avy_logo',
    description:
      'De meisjes van het Smeer ‘m, onder leiding van Saskia en Martine, doen dagelijks hun uiterste best om met een stralende lach de lekkerste lunchgerechten en koffies te serveren.',
    portraitImage: smeerm_2,
    portraitImageSmall: smeerm_3,
    link: 'https://smeer-m.nl',
    internallink: false,
    btnText: 'Bekijk de website&nbsp;→',
  },
}
const {
  logos: { woodwatch, petossi, storageShare, codaisseur },
} = images

class HorecaPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      active: false,
      activeID: '',
    }

    this.setActive = this.setActive.bind(this)
  }

  setActive(event) {
    let id = event.target.id

    if (id === this.state.activeID) {
      id = ''
    }

    this.setState({
      active: !this.state.active,
      activeID: id,
    })
  }

  render() {
    return (
      <Layout location={this.props.location}>
        <main className="animated branche fadeInPage">
          <Helmet>
            <title>Moderne Salarisadministratie voor Horeca</title>
            <meta
              name="description"
              content="Ontdek moderne en eenvoudige salarisadministratie voor de horeca, speciaal voor ondernemers. ✅ Probeer nu gratis!"
            />
            <meta
              itemprop="name"
              content="Moderne Salarisadministratie voor Horeca"
            />
            <meta
              itemprop="description"
              content="Ontdek moderne en eenvoudige salarisadministratie voor de horeca, speciaal voor ondernemers. ✅ Probeer nu gratis!"
            />
            <meta
              itemprop="image"
              content="https://employes.nl/static/meta-horeca.jpg"
            />

            {/* Windows tags */}
            <meta name="msapplication-TileColor" content="#ffffff" />
            <meta
              name="msapplication-TileImage"
              content="static/favicon/mstile-144x144.png"
            />

            {/* OpenGraph tags */}
            <meta property="og:url" content="" />
            <meta property="og:type" content="website" />
            <meta
              property="og:title"
              content="Moderne Salarisadministratie voor Horeca"
            />
            <meta
              property="og:description"
              content="Ontdek moderne en eenvoudige salarisadministratie voor de horeca, speciaal voor ondernemers. ✅ Probeer nu gratis!"
            />
            <meta
              property="og:image"
              content="https://employes.nl/static/meta-horeca.jpg"
            />

            {/* Twitter Card tags */}
            <meta name="twitter:card" content="summary_large_image" />
            <meta
              name="twitter:title"
              content="Moderne Salarisadministratie voor Horeca"
            />
            <meta
              name="twitter:description"
              content="Ontdek moderne en eenvoudige salarisadministratie voor de horeca, speciaal voor ondernemers. ✅ Probeer nu gratis!"
            />
            <meta
              name="twitter:image"
              content="https://employes.nl/static/meta-horeca.jpg"
            />
          </Helmet>

          <div className="container-md padding-xl">
            <div className="grid center text-center">
              <div className="col-10">
                <h1>Eenvoudige salarisadministratie horeca</h1>
                <p className="streamer large center margin-m-bottom">
                  Employes is een handige salarisadministratie tool voor je
                  restaurant, broodjeszaak, koffiebar of andere
                  horeca-onderneming.
                </p>
                <a
                  className="btn primary lg margin-s-bottom"
                  href={appUrl}
                  rel="nofollow"
                >
                  Probeer nu gratis
                </a>
                <br></br>
                <p className="small sky-dark hide-mobile">
                  Je kunt Employes geheel vrijblijvend testen.
                </p>

                {/*<div className="logo-grid hide-mobile block">
                  <div className="container-sm margin-xs-bottom">
                    <div className="grid yg align-middle logos">
                      {[codaisseur, petossi, storageShare, woodwatch].map((img, idx) => (
                        <div className="col-3 align-middle no-mar" key={idx}>
                          <div className={`logo ${idx === 1 ? 'petossi' : ''}`}>
                            <img src={img} />
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="container-sm">
                    <div className="grid center text-center yg align-middle">
                      <p className="hide-mobile">We helpen veel verschillende bekende en minder bekende horeca-bedrijven.</p>
                    </div>
                  </div>
                </div>
                */}
              </div>
            </div>
          </div>

          <div className="container-md padding-m-top">
            <div className="grid center text-center">
              <div className="col-12">
                <img
                  className="photo"
                  alt="Smeerm horeca salarisadministratie"
                  src={smeerm_1}
                />
              </div>
            </div>
          </div>

          <section className="padding-xl padding-xxl-bottom">
            <div className="container-md flex">
              <div className="text-container">
                <h2>Online salarisadministratie voor horeca</h2>
                <p>
                  Als horeca-ondernemer hou je je graag bezig met zaken die
                  ertoe doen. Hierbij hoort echter ook een strakke
                  salarisadministratie. Employes is een handige
                  salarisadministratie tool voor restaurants en andere
                  horeca-ondernemingen. Je voert eenvoudig en snel gewerkte uren
                  in en je kunt direct je werknemers verlonen. Daarnaast
                  verstuur je eenvoudig de loonstroken naar je werknemers en
                  betaal je de bedragen met een betaalbestand.
                </p>

                <h3 className="margin-m-top">
                  Dynamische salarisverwerking voor horecapersoneel
                </h3>
                <p>
                  Je hebt als <b>horeca</b>-ondernemer veel te maken met
                  flexibele werknemers en oproepkrachten. Deze flexibiliteit is
                  de spil in je horeca-onderneming, maar brengt op het gebied
                  van administratie vaak extra werk met zich mee. Employes kent
                  de uitdaging van de vaak wisselende gewerkte uren en de moeite
                  die het kost om deze uren juist te verwerken in de
                  salarisadministratie. Door Employes te koppelen aan je
                  urenregistratiesysteem vul je binnen enkele seconden de
                  flexibele uren in, waarna dit meteen wordt verwerkt in de
                  verloning.
                </p>

                <h3 className="margin-m-top">
                  Automatische arbeidscontracten voor horecapersoneel
                </h3>
                <p>
                  Een andere uitdaging in de salarisadministratie voor{' '}
                  <b>horeca</b> is het registreren van vaak vele in- en
                  uitdienstregelingen. Weer een nieuwe medewerker in dienst? Met
                  Employes kan je heel eenvoudig automisch arbeidscontracten
                  opstellen voor je personeel. Wij bieden templates voor
                  contracten aan op basis van de modelcontracten van de KHN.
                  Daarnaast bieden wij ook voorzieningen voor de WAB. Zo kun je
                  ook een aanbiedingsbrief opmaken in Employes.
                </p>

                <h3 className="margin-m-top">Voordeliger dan via payroll</h3>
                <p>
                  Doordat het personeel op de loonlijst van je eigen{' '}
                  <b>horecabedrijf</b> staat, betaal je geen payroll-factor over
                  het bruto loon.Dat kan behoorlijk in de kosten schelen. De
                  ondernemers die naar Employes zijn overgestapt, besparen
                  tussen de 5% en 10% van het bruto loon. En door de handige
                  applicatie van Employes is het aanmelden van werknemers en het
                  doorgeven van uren net zo eenvoudig als het uitbesteden aan
                  een payroll-bedrijf.
                </p>

                <h3 className="margin-m-top">
                  Voordelen van Employes voor de horeca salarisadministratie
                </h3>
                <ul>
                  <li>Directe online verloning</li>
                  <li>Geen salariskennis nodig</li>
                  <li>Automatisch arbeidscontracten opmaken</li>
                  <li>Actuele regelgeving omtrent de CAO (KHN) en WAB</li>
                  <li>
                    Koppelingen met diverse boekhoudprogramma’s, zoals Moneybird
                    en Exact Online
                  </li>
                  <li>
                    Koppelingen met urenregistratiesystemen, zoals Werktijden.nl
                    en Keeping
                  </li>
                </ul>
                <p>
                  <a className="link mulberry" href={appUrl} rel="nofollow">
                    Probeer Employes gratis uit{' '}
                  </a>
                  en zie hoe het voor jou kan werken.
                </p>

                <h3 className="margin-m-top">
                  Maak vrijblijvend kennis met Employes
                </h3>
                <p>
                  Ben je enthousiast over wat je zojuist leest en wil je
                  dolgraag kennismaken met ons slimme salarisadministratie voor
                  de <strong>horeca</strong>? Probeer het dan gratis uit en maak
                  geheel vrijblijvend een account aan op de site. Wij zijn ervan
                  overtuigd dat ons online systeem jou diverse voordelen
                  oplevert. Na afloop van je proefperiode betaal je een vast
                  bedrag per maand plus een bepaald bedrag per medewerker. Heb
                  je vragen of behoefte aan advies op maat of twijfel je over de
                  gebruiksvriendelijkheid van de oplossing voor{' '}
                  <strong>horeca</strong>? Neem contact op met onze
                  professionals. Employes staat je altijd graag te woord en
                  biedt ondersteuning waar dat nodig is.
                </p>
              </div>

              <div className="cta-card right">
                <img src={articleImage} alt="Background image of the card" />
                <p className="eyebrow">Probeer nu gratis</p>
                <h5 className="margin-s-bottom">
                  Ervaar het gemak en probeer Employes gratis uit.
                </h5>
                <a href={appUrl + signupRoute} className="btn primary">
                  Probeer nu
                </a>
              </div>
            </div>
          </section>

          <div className="container-md margin-l-bottom">
            <div className="grid between align-middle">
              <div className="col-6">
                <h2>“Zeer gebruiksvriendelijk en overzichtelijk.”</h2>
                <p>
                  <b>Bedrijf:</b> Smeer'm
                </p>
                <p>
                  <b>Klant sinds:</b> januari 2020
                </p>
                <p>
                  <b>Aantal werknemers:</b> 20
                </p>
              </div>
              <ClientCard
                className="col-6 no-pad"
                person={dummyKlantVerhaal.person}
                isSmall={window.innerWidth < 768}
              />
            </div>
          </div>
        </main>
      </Layout>
    )
  }
}

export default HorecaPage
